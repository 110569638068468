/* Small CSS to Hide elements at 1520px size */
@media (max-width: 1520px) {
	.left-svg {
		display: none;
	}
}

/* small css for the mobile nav close */
#nav-mobile-btn.close span:first-child {
	transform: rotate(45deg);
	top: 4px;
	position: relative;
	background: #a0aec0;
}

#nav-mobile-btn.close span:nth-child(2) {
	transform: rotate(-45deg);
	margin-top: 0;
	background: #a0aec0;
}

/* Colors from https://coolors.co/0c8b97-2dacf0-f9f7f3-eddea4-f58b51 */
.plain {
	fill: #0C8B97;
}

.java11 {
	fill: #2DACF0;
}

.java17 {
	fill: #F58B51;
}

text {
	fill: currentColor
}

@tailwind base;
@tailwind components;
@tailwind utilities;
